<template>
  <div class="section-title">
    <div v-if="icon" class="icon-wrapper">
      <IconComponent
        class="icon-wrapper-bg"
        :icon="IconWrapperSvg"
        size="xl"
      />
      <IconComponent :icon="icon" />
    </div>
    <h3 class="title">
      {{title}}
    </h3>
  </div>
</template>

<script>
import LocaleMessages from 'vue-i18n'
import IconComponent from './IconComponent.vue'
import IconWrapperSvg from '@/assets/icons/icon-wrapper.svg'

export default {
  name: 'SectionTitle',
  components: { IconComponent },
  data () {
    return {
      IconWrapperSvg
    }
  },
  props: {
    title: [String, LocaleMessages],
    icon: {
      type: [String, Object],
      optional: true
    }
  }
}
</script>

<style scoped lang="scss">
.section-title {
  display: flex;
  align-items: center;
  gap: $spacing-12;

  .icon-wrapper {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    position: relative;
    width: $section-title-icon-wrapper-size;
    height: $section-title-icon-wrapper-size;
    border-radius: 50%;

    &-bg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .title {
    @extend %font-sb-xl;
  }
}
</style>
