import { CURRENCY_USD } from '@/constants'

export function handleCurrency (
  value,
  minimumFractionDigits = 0,
  maximumFractionDigits = 0
) {
  return value.toLocaleString('us', {
    style: 'currency',
    currency: CURRENCY_USD,
    minimumFractionDigits,
    maximumFractionDigits
  })
}
