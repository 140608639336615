<template>
  <div
    class="panel-base"
    :class="[
      variant,
      {
        'align-center': alignCenter,
        dense,
        expand
      }
    ]"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'PanelBase',
  props: {
    alignCenter: {
      type: Boolean,
      optional: true
    },
    dense: {
      type: Boolean,
      optional: true
    },
    expand: {
      type: Boolean,
      optional: true
    },
    variant: {
      type: String,
      optional: true,
      default: 'blurred',
      validator: (value) => ['blue', 'blurred'].includes(value)
    }
  }
}
</script>

<style scoped lang="scss">
.panel-base {
  display: flex;
  flex-direction: column;
  gap: $spacing-24;
  width: $panel-width;
  padding: $spacing-32;
  background: $color-panel-bg;
  color: $color-white;
  border-radius: $border-radius-8;
  border: 1px solid $color-panel-border;
  backdrop-filter: blur(12px);

  @include mobile {
    width: $panel-width-mobile;
    padding: $spacing-24;
  }

  &.blue {
    background: $color-panel-blue;
    border-image-source: $color-panel-blue;
  }

  &.align-center {
    align-items: center;
  }

  &.dense {
    flex-shrink: 0;
    width: $panel-dense-width;
  }

  &.expand {
    width: 100%;
  }
}
</style>
