<template>
  <div
    ref="expandableWrapperRef"
    class="expandable-wrapper"
  >
    <div
      ref="expandableWrapperContainerRef"
      class="container"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExpandableWrapper',
  data () {
    return {
      timeoutId: null
    }
  },
  mounted () {
    this.toggleIsExpanded(this.isExpanded)
  },
  methods: {
    toggleIsExpanded (isExpanded) {
      if (!this.$refs.expandableWrapperRef) {
        return
      }
      this.$refs.expandableWrapperRef.style.gridTemplateRows = isExpanded ? '1fr' : '0fr'
      this.$refs.expandableWrapperRef.style.opacity = isExpanded ? '1' : '0'

      if (isExpanded) {
        this.timeoutId = setTimeout(() => {
          this.$refs.expandableWrapperContainerRef.style.overflow = 'unset'
        }, 600) // Transition time
      } else {
        clearTimeout(this.timeoutId)
        this.$refs.expandableWrapperContainerRef.style.overflow = 'hidden'
      }
    }
  },
  props: {
    isExpanded: Boolean
  },
  watch: {
    isExpanded (value) {
      this.toggleIsExpanded(value)
    }
  }
}
</script>

<style scoped lang="scss">
.expandable-wrapper {
  display: grid;
  grid-template-rows: 0fr;
  transition: $expand-transition;
  opacity: 0;

  .container {
    overflow: hidden;
  }
}
</style>
