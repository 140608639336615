export function getFromLocalStorage (key, isObject = false) {
  const data = localStorage.getItem(key)
  if (!data) {
    return null
  }
  return isObject ? JSON.parse(data) : data
}

export function removeFromLocalStorage (token) {
  localStorage.removeItem(token)
}
