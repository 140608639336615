<template>
  <div
    class="table-component"
    :class="{ 'horizontal-scroll': horizontalScroll }"
  >
    <table>
      <TableHeader
        :header-config="headerConfig"
        :sort="sort"
        @sort="(sortValue) => $emit('sort', sortValue)"
      />
      <tbody class="table-body">
        <tr
          v-for="(row, index) in data"
          :key="index"
          class="table-row"
        >
          <td
              v-for="(key, index) in Object.keys(headerConfig)"
              :key="key"
              class="table-cell"
          >
            <div class="table-cell-content">
              <slot
                v-if="$slots[`column-${key}`]"
                :value="row[key]"
                :index="index"
                :row="row"
                :name="`column-${key}`"
              />
              <TextEllipsis v-else>
                {{ row[key] }}
              </TextEllipsis>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import TextEllipsis from '@/components/TextEllipsis.vue'
import TableHeader from './TableHeader.vue'

export default {
  name: 'TableComponent',
  components: { TextEllipsis, TableHeader },
  props: {
    data: Array,
    headerConfig: Object,
    horizontalScroll: {
      type: Boolean,
      optional: true
    },
    sort: {
      type: Object,
      optional: true
    }
  }
}
</script>

<style scoped lang="scss">
.table-component {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: fit-content;
  color: $color-white;

  &.horizontal-scroll {
    overflow: scroll hidden;
  }

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 $spacing-16;
  }

  .table-row {
    @include generate-shadow($sidebar-shadow);

    height: $table-row-height;
    background: linear-gradient(90deg, $color-blue-light 0%, #1E3566 100%);
    border: 1px solid $color-table-row-border;
    border-radius: $border-radius-8;

    .table-cell {
      padding-inline: $spacing-8;

      &:first-of-type {
        padding-left: $spacing-24;
        border-radius: $spacing-8 0 0 $spacing-8;
      }

      &:last-of-type {
        padding-right: $spacing-24;
        border-radius: 0 $spacing-8 $spacing-8 0;
      }
    }
  }

  .table-cell-content {
    .text-ellipsis {
      @include mobile {
        width: 99%;
      }
    }
  }
}
</style>
