<template>
  <div class="account">
    <PageTitle :title="$t('common.account')" />

    <PanelBase expand>
      <div v-if="!isMobile" class="desktop-info-wrapper">
        <div class="text-wrapper">
          <SectionTitle :title="username" />
          <p class="text"> {{ email }} </p>
        </div>
        <ButtonBase
          variant="outlined"
          fit-content
          medium-size
          @click="logout"
        >
          {{ $t('common.logout') }}
        </ButtonBase>
      </div>
      <template v-else>
        <div class="text-wrapper">
          <SectionTitle :title="username" />
          <p class="mail"> {{ email }} </p>
        </div>
        <ButtonBase
          variant="outlined"
          expand
          medium-size
          @click="logout"
        >
          {{ $t('common.logout') }}
        </ButtonBase>
      </template>
    </PanelBase>

    <PanelBase expand>
      <SpinnerLoader
        v-if="isLoading"
        is-big
      />
      <template v-else>
        <div v-if="!isMobile && level === 1" class="desktop-info-wrapper">
          <div class="text-wrapper">
            <SectionTitle :title="$t('account.identification')" />
            <p class="text">
              {{ $t('account.currentKycLevel', { level }) }}
            </p>
          </div>
          <ButtonBase
              v-if="level === 1"
              fit-content
              medium-size
              variant="secondary"
              id="blockpass-kyc-connect"
          >
            {{ $t('account.completeKyc') }}
          </ButtonBase>
          <div v-else class="passed-kyc-wrapper">
            <IconComponent :icon="TickCircleIcon" />
            {{ $t('account.passedKyc') }}
          </div>
        </div>
        <template v-else>
          <div class="text-wrapper">
            <SectionTitle :title="$t('account.identification')" />
            <p class="text">
              {{ $t('account.currentKycLevel', { level }) }}
            </p>
          </div>
          <ButtonBase
              v-if="level === 1"
              expand
              medium-size
              variant="secondary"
              id="blockpass-kyc-connect"
          >
            {{ $t('account.completeKyc') }}
          </ButtonBase>
          <div v-else class="passed-kyc-wrapper">
            <IconComponent :icon="TickCircleIcon" />
            {{ $t('account.passedKyc') }}
          </div>
        </template>
      </template>
    </PanelBase>

    <PanelBase expand>
      <SpinnerLoader
        v-if="isLoading"
        is-big
      />
      <template v-else>
        <SectionTitle :title="$t('account.add2fa')" />
        <ButtonBase
            v-if="!otp"
            expand
            medium-size
            variant="secondary"
            @click="toggleAuthModal(!isModalVisible)"
        >
          {{ $t('account.addGoogleAuthenticator') }}
        </ButtonBase>
        <div v-else class="passed-kyc-wrapper">
          <IconComponent :icon="TickCircleIcon" />
          {{ $t('account.googleAuthentication') }}
        </div>
      </template>
    </PanelBase>

    <History v-if="isMobile"/>

    <ModalBase
      :is-visible="isModalVisible"
      @close="toggleAuthModal(!isModalVisible)"
    >
      <form class="modal-wrapper">
        <div class="content-wrapper">
          <h1 class="modal-title">
            {{ $t('account.addGoogleAuthenticator') }}
          </h1>
          <HorizontalRule />
        </div>
        <p class="text">
          {{ $t('account.step1') }}
        </p>
        <div class="qr-wrapper">
          <img
            :src="qrURL"
            alt="QR Image Load Failed"
            class="qr"
          >
        </div>
        <div class="content-wrapper">
          <p class="text">
            {{ $t('account.step2') }}
          </p>
          <InputField
            v-model="otpCode"
            expand
            :placeholder="$t('account.enter2faCode')"
          />
        </div>
        <HorizontalRule />
        <div class="button-wrapper">
          <ButtonBase
            medium-size
            :expand="isMobile"
            :fit-content="!isMobile"
            variant="outlined"
            @click="toggleAuthModal(!isModalVisible)"
          >
            {{ $t('common.close') }}
          </ButtonBase>
          <ButtonBase
            medium-size
            type="submit"
            :expand="isMobile"
            :fit-content="!isMobile"
            :loading="isFormLoading"
            :disabled="!otpCode"
            @click="confirmQrCode"
          >
            {{ $t('common.confirm') }}
          </ButtonBase>
        </div>
      </form>
    </ModalBase>

  </div>
</template>

<script>
import callApi from '@/api'
import { useNotifications, useResize } from '@/composables'
import { STORAGE_KEY_USER_INFO } from '@/constants'
import { getFromLocalStorage, removeFromLocalStorage } from '@/utils'
import History from '@/views/History.vue'
import ButtonBase from '@/components/ButtonBase.vue'
import HorizontalRule from '@/components/HorizontalRule.vue'
import IconComponent from '@/components/IconComponent.vue'
import InputField from '@/components/InputField.vue'
import ModalBase from '@/components/ModalBase.vue'
import PageTitle from '@/components/PageTitle.vue'
import PanelBase from '@/components/PanelBase.vue'
import SectionTitle from '@/components/SectionTitle.vue'
import SpinnerLoader from '@/components/SpinnerLoader.vue'
import TickCircleIcon from '@/assets/icons/tick-circle-icon.svg'

export default {
  name: 'Account',
  data () {
  	return {
  		username: '',
      email: '',
      level: 1,
      status: 'no',
      qrURL: '',
      otpCode: null,
      otp: false,
      isFormLoading: false,
      isLoading: false,
      isModalVisible: false,
      TickCircleIcon
  	}
  },
  components: {
    SpinnerLoader,
    ButtonBase,
    History,
    HorizontalRule,
    IconComponent,
    InputField,
    ModalBase,
    PanelBase,
    PageTitle,
    SectionTitle
  },
  async created () {
    await this.fetchData()
    this.loadBlockpassWidget()
  },
  methods: {
    async fetchData () {
      this.isLoading = true
      const { success, result } = await callApi.get('/api/get-kyc', {}, true)
      if (success) {
        this.level = result.level
        this.otp = result.otp
        this.status = result.status
        this.isLoading = false
      } else {
        this.pushNotification({
          titleKey: 'notifications.error.userInfoObtain',
          type: 'error'
        })
      }
      const userData = getFromLocalStorage(STORAGE_KEY_USER_INFO, true)
      this.username = userData.username
      this.email = userData.email
    },
    loadBlockpassWidget () {
      const blockpass = new window.BlockpassKYCConnect(
        'kyc_36562', // service client_id from the admin console
        {
          env: 'prod',
          refId: window.userID,
          email: this.email
        }
      )

      blockpass.startKYCConnect()

      blockpass.on('KYCConnectSuccess', () => {
        // add code that will trigger when data have been sent.
        console.log('KYC Connect success')
        location.reload()
      })
    },
    logout () {
      removeFromLocalStorage(STORAGE_KEY_USER_INFO)
      this.$router.push({ path: '/logout' })
    },
    async getQrCode () {
      const { success, result } = await callApi.get('/api/add-opt')
      if (success) {
        this.qrURL = result.image
      }
    },
    async confirmQrCode () {
      this.isFormLoading = true
      const { success } = await callApi.post('/api/add-opt', { token: this.otpCode })
      if (success) {
        await this.fetchData()
        await this.toggleAuthModal(!this.isModalVisible)
        this.pushNotification({
          titleKey: 'notifications.success.addedGoogleAuthenticator',
          type: 'success'
        })
      }
      this.isFormLoading = false
    },
    async toggleAuthModal (value) {
      if (!this.qrURL) {
        await this.getQrCode()
      }
      this.isModalVisible = value
    }
  },
  setup () {
    const { pushNotification } = useNotifications()
    const { isMobile } = useResize()
    return { isMobile, pushNotification }
  }
}
</script>

<style scoped lang="scss">
.account {
  display: flex;
  flex-direction: column;
  gap: $spacing-24;
  padding: $spacing-32 $spacing-24;
  width: 60%;

  @include mobile {
    padding: $spacing-16 $spacing-20;
    width: 100%;
    height: fit-content;
  }

  .desktop-info-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .text-wrapper {
    display: flex;
    flex-direction: column;
    gap: $spacing-12;
  }

  .text {
    @extend %font-medium-rg;
  }

  .passed-kyc-wrapper {
    display: flex;
    align-items: center;
    gap: $spacing-8;
    color: $color-primary-05;
  }

  .modal-wrapper {
    display: flex;
    flex-direction: column;
    gap: $spacing-32;

    .content-wrapper {
      display: flex;
      flex-direction: column;
      gap: $spacing-24;
    }

    .modal-title {
      @extend %font-sb-2xl;
    }

    .qr-wrapper {
      display: flex;
      justify-content: center;
    }

    .qr {
      width: $qr-size;
      height: $qr-size;
    }
  }

  .button-wrapper {
    display: flex;
    justify-content: end;
    gap: $spacing-16;

    @include mobile {
      flex-direction: column-reverse;
    }
  }
}
</style>
