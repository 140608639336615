<template>
   <div
     class="footer-menu"
     ref="footer"
   >
     <ButtonBase
         v-for="element in sidebarConfig"
         :key="element.name"
         :to="element.to"
         variant="footer"
     >
       <IconComponent
         :icon="element.icon"
         size="md"
       />
       {{ element.name }}
     </ButtonBase>
   </div>
</template>

<script>
import ButtonBase from '@/components/ButtonBase.vue'
import IconComponent from '@/components/IconComponent.vue'
import CategoryIcon from '@/assets/icons/category-icon.svg'
import EarnIcon from '@/assets/icons/earn-icon.svg'
import SwapIcon from '@/assets/icons/swap-icon.svg'
import UserIcon from '@/assets/icons/user-icon.svg'
import WalletIcon from '@/assets/icons/wallet-icon.svg'
export default {
  name: 'FooterMenu',
  components: {
    IconComponent,
    ButtonBase
  },
  computed: {
    activeTabIndex () {
      return this.sidebarConfig.findIndex((tab) => tab.to === this.$route.path)
    }
  },
  data () {
    return {
      activeTabElement: null,
      activeTabPosition: 0,
      activeTabWidth: 0,
      CategoryIcon,
      SwapIcon,
      EarnIcon,
      UserIcon,
      WalletIcon
    }
  },
  methods: {
    setActiveTab (value) {
      this.activeTabElement = this.$refs.footer.children[value || this.activeTabIndex]
      this.activeTabPosition = `${this.activeTabElement.offsetLeft}px`
      this.activeTabWidth = `${this.activeTabElement.offsetWidth}px`
    }
  },
  mounted () {
    this.setActiveTab()
  },
  setup () {
    const sidebarConfig = [
      {
        name: 'Dashboard',
        icon: CategoryIcon,
        to: '/dashboard'
      },
      {
        name: 'Wallet',
        icon: WalletIcon,
        to: '/wallet'
      },
      {
        name: 'Earn',
        icon: EarnIcon,
        to: '/stake'
      },
      {
        name: 'Swap',
        icon: SwapIcon,
        to: '/swap'
      },
      {
        name: 'Account',
        icon: UserIcon,
        to: '/account'
      }
    ]
    return { sidebarConfig }
  },
  watch: {
    activeTabIndex (value) {
      this.setActiveTab(value)
    }
  }
}
</script>

<style scoped lang="scss">
.footer-menu {
  --after-left: v-bind(activeTabPosition);
  --after-width: v-bind(activeTabWidth);

  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: $footer-height;
  padding: $spacing-16;
  border-radius: $spacing-8 $spacing-8 0 0;
  border-top: 1px solid $color-blue-dark;
  color: white;
  background: $color-footer-bg;
  backdrop-filter: blur($blur-footer);

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(var(--after-width) + $footer-after-additional-width);
    height: $footer-after-additional-height;
    background: $color-white;
    border-radius: $border-radius-4 $border-radius-4 0 0;
    transform: translateX(calc(var(--after-left) - $footer-after-additional-width / 2));
    transition: $transition-ease;
  }
}
</style>
