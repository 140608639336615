import Vue from 'vue'
import VueI18n from 'vue-i18n'
import VueRouter from 'vue-router'
import App from './App.vue'
import router from './router'
import en from './locales/en.json'
import './styles/setup.scss'

Vue.config.productionTip = false

Vue.use(VueI18n)
Vue.use(VueRouter)

const i18n = new VueI18n({
  locale: 'en',
  messages: { en }
})

new Vue({
  i18n,
  router,
  render: h => h(App)
}).$mount('#app')
