<template>
  <div class="notification-item">
    <div class="notification-header">
      <div
        class="notification-mark"
        :class="[type]"
      />
      <h6 class="notification-title">
        {{ $t(titleKey, titleProperties) }}
      </h6>
    </div>
    <ButtonBase
      class="notification-close"
      variant="icon"
      fit-content
      @click="removeNotification(id)"
    >
      <IconComponent
        :icon="CloseIcon"
        size="sm"
      />
    </ButtonBase>
  </div>
</template>

<script>
import { useNotifications } from '@/composables'
import ButtonBase from '@/components/ButtonBase.vue'
import IconComponent from '@/components/IconComponent.vue'
import CloseIcon from '@/assets/icons/close-icon.svg'

export default {
  name: 'NotificationItem',
  components: { ButtonBase, IconComponent },
  data () {
    return {
      CloseIcon
    }
  },
  props: {
    id: String,
    titleKey: String,
    type: {
      type: String,
      validator: (value) => ['error', 'info', 'success'].includes(value)
    },
    titleProperties: {
      type: Object,
      optional: true
    }
  },
  setup () {
    const { removeNotification } = useNotifications()
    return { removeNotification }
  }
}
</script>

<style scoped lang="scss">
.notification {
  --mark-bg-color: #{$color-primary-20};

  &-item {
    @include generate-shadow($notification-shadow);

    display: flex;
    justify-content: space-between;
    width: $notification-width;
    padding: $spacing-12;
    border-radius: $border-radius-8;
    background: $color-blue-light;

    @include mobile {
      width: 100%
    }
  }

  &-header {
    display: flex;
    align-items: center;
    gap: $spacing-16;
    width: 100%;
    word-break: break-word;
  }

  &-mark {
    min-width: $notification-mark-width;
    height: 100%;
    background: var(--mark-bg-color);

    &.error {
      --mark-bg-color: #{$color-red};
    }

    &.info {
      --mark-bg-color: #{$color-primary-20};
    }

    &.success {
      --mark-bg-color: #{$color-green};
    }
  }

  &-title {
    @extend %font-medium-rg;

    color: $color-white;
  }

  &-close {
    flex-shrink: 0;
  }
}
</style>
