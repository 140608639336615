var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-base",class:[
    _vm.variant,
    {
      'align-center': _vm.alignCenter,
      dense: _vm.dense,
      expand: _vm.expand
    }
  ]},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }