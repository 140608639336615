<template>
  <div
      class="bottom-message"
      :class="{ error: !!errorMessage }"
  >
    {{ errorMessage || infoMessage || '' }}
  </div>
</template>

<script>
export default {
  name: 'InputBottomMessage',
  props: {
    errorMessage: {
      type: String,
      optional: true
    },
    infoMessage: {
      type: String,
      optional: true
    }
  }
}
</script>

<style lang="scss" scoped>
.bottom-message {
  @extend %font-medium-xs;

  height: $input-bottom-message-height;
  margin-bottom: $spacing-4;
  color: $color-gray;

  &.error {
    color: $color-red;
  }
}
</style>
