<template>
  <div class="set-password">
    <PanelBase align-center>
      <GctLogo class="logo"/>
      <h6 class="title">
        {{  $t('auth.setPassword') }}
      </h6>
      <form class="form">
        <InputField
          expand
          hide-error
          type="password"
          v-model="data.password"
          :label="$t('auth.newPassword')"
          :placeholder="$t('auth.newPassword')"
        />
        <InputField
          expand
          hide-error
          type="password"
          v-model="data.confirmPassword"
          :label="$t('auth.repeatPassword')"
          :placeholder="$t('auth.repeatPassword')"
        />
        <ButtonBase
          expand
          medium-size
          type="submit"
          :loading="isFormLoading"
          @click="handleSubmit"
        >
          {{ $t('auth.setNewPassword') }}
        </ButtonBase>
      </form>
      <div class="login-wrapper">
        {{ $t('auth.backTo') }}
        <RouterLink class="link" to="/">
          {{ $t('auth.login') }}
        </RouterLink>
      </div>
    </PanelBase>
  </div>
</template>

<script>
import callApi from '@/api'
import { useNotifications } from '@/composables'
import { STORAGE_KEY_USER_INFO_RESET } from '@/constants'
import {
  getFromLocalStorage,
  removeFromLocalStorage
} from '@/utils'
import ButtonBase from '@/components/ButtonBase.vue'
import InputField from '@/components/InputField.vue'
import PanelBase from '@/components/PanelBase.vue'
import GctLogo from '@/assets/gct-logo.svg'

export default {
  name: 'SetPassword',
  components: {
    ButtonBase,
    GctLogo,
    InputField,
    PanelBase
  },
  data () {
  	return {
      data: {
        password: '',
        confirmPassword: ''
      },
      isFormLoading: false,
      userInfo: null
  	}
  },
  mounted () {
    this.userInfo = getFromLocalStorage(STORAGE_KEY_USER_INFO_RESET, true)
    if (!this.userInfo) {
      this.pushNotification({
        titleKey: 'notifications.error.invalidChangeToken',
        type: 'error'
      })
      this.$router.push({ path: '/resetpassword' })
    }
  },
  methods: {
  	async handleSubmit (event) {
      event.preventDefault()
      this.isFormLoading = true
      if (this.data.password !== this.data.confirmPassword) {
        this.pushNotification({
          titleKey: 'notifications.error.passwordArentSame',
          type: 'error'
        })
        this.isFormLoading = false
        return
      }
      const body = {
        password: this.data.password,
        uidb64: this.userInfo.user,
        token: this.userInfo.token
      }
      const { success } = await callApi.post('/api/send-new-password', body)
      if (success) {
        this.pushNotification({
          titleKey: 'notifications.success.resetPassword',
          type: 'success'
        })
        removeFromLocalStorage(STORAGE_KEY_USER_INFO_RESET)
        await this.$router.push({ path: '/' })
      }
      this.isLoading = false
  	}
  },
  setup () {
    const { pushNotification } = useNotifications()
    return { pushNotification }
  }
}
</script>

<style scoped lang="scss">
.set-password {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;

  .logo {
    height: $logo-height
  }

  .title {
    @extend %font-medium-sm;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: $spacing-24;
    width: 100%;
  }

  .link {
    @extend %font-sb-sm;

    color: $color-primary-05;
    cursor: pointer;
  }

  .login-wrapper {
    @extend %font-medium-sm;

    display: flex;
    gap: $spacing-4;
  }
}
</style>
