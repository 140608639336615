<template>
  <hr class="horizontal-rule" />
</template>

<script>
export default {
  name: 'HorizontalRule'
}
</script>

<style scoped lang="scss">
.horizontal-rule {
  border-color: $color-hr;
}
</style>
