import { ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'

const notificationList = ref([])

export function useNotifications () {
  function removeNotification (id) {
    notificationList.value = notificationList.value.filter(
      (singleNotification) => singleNotification.id !== id
    )
  }

  function pushNotification (rawNotification) {
    const notification = {
      ...rawNotification,
      id: uuidv4()
    }

    notificationList.value.push(notification)

    setTimeout(() => {
      removeNotification(notification.id)
    }, notification.timeout || 5000)
  }

  return {
    notificationList,
    pushNotification,
    removeNotification
  }
}
