var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.component,{tag:"Component",staticClass:"button",class:[
      _vm.variant,
      {
        disabled: _vm.disabled,
        expand: _vm.expand,
        loading: _vm.loading,
        'fit-content': _vm.fitContent,
        'medium-size': _vm.mediumSize
      },
    ],attrs:{"type":_vm.type,"disabled":_vm.disabled || _vm.loading,"to":_vm.to,"href":_vm.href,"target":_vm.href ? '_blank' : null,"rel":_vm.href ? 'noopener noreferrer' : null},on:{"click":(e) => _vm.$emit('click', e)}},[(_vm.loading)?_c('SpinnerLoader'):_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }