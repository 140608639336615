<template>
  <thead class="table-header">
    <th
      v-for="(key, index) in Object.keys(headerConfig)"
      :key="index"
      class="header-cell"
    >
      <div class="header-cell-content">
        {{ $t(headerConfig[key]?.labelKey) }}
        <ButtonBase
            :key="sort?.order"
            v-if="headerConfig[key]?.sortable"
            variant="icon"
            @click="handleSort(key)"
        >
          <IconComponent
            :icon="SortDownIcon"
            size="md"
            class="sort-icon"
            :class="{ active: isSortActive(key, 'DESC') }"
          />
          <IconComponent
            :icon="SortUpIcon"
            size="md"
            class="sort-icon"
            :class="{ active: isSortActive(key, 'ASC') }"
          />
        </ButtonBase>
      </div>
    </th>
  </thead>
</template>

<script>
import ButtonBase from '@/components/ButtonBase.vue'
import IconComponent from '@/components/IconComponent.vue'
import SortDownIcon from '@/assets/icons/sort-icon-down.svg'
import SortUpIcon from '@/assets/icons/sort-icon-up.svg'

export default {
  name: 'TableHeader',
  components: { IconComponent, ButtonBase },
  data () {
    return {
      SortDownIcon,
      SortUpIcon
    }
  },
  methods: {
    handleSort (field) {
      const order = (
        this.sort?.field &&
        this.sort.field === field &&
        this.sort.order === 'DESC'
      ) ? 'ASC' : 'DESC'
      this.$emit('sort', { field, order })
    },
    isSortActive (field, order) {
      if (!this.sort?.field) {
        return false
      }
      return this.sort?.field === field && this.sort?.order === order
    }
  },
  props: {
    headerConfig: Object,
    sort: {
      type: Object,
      optional: true
    }
  }
}
</script>

<style scoped lang="scss">
.table-header {
  height: $table-header-height;
  user-select: none;

  .header-cell {
    height: $table-header-cell-height;
    padding-inline: $spacing-8;

    &:first-of-type {
      padding-left: $spacing-24;
    }

    &:last-of-type {
      padding-right: $spacing-24;
    }
  }

  .header-cell-content {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: $spacing-8;

    .sort-icon {
      color: $color-table-header-sort-icon;

      &.active {
        color: $color-white;
      }
    }
  }
}
</style>
