<template>
  <div
    class="input-field"
    :class="{'fit-content': fitContent}"
  >
    <label class="input-wrapper">
      <div
          v-if="label"
          class="input-label"
      >
        {{ label }}
      </div>
      <div
          class="input-container"
          :class="{
            error,
            disabled,
            clickable,
            expand
        }"
          @click="handleClick"
          @keydown.enter="handleClick"
      >
        <span
            v-if="$slots['icon-left']"
            class="icon-container"
            :class="{ clickable: clickableIcon }"
        >
          <slot name="icon-left" />
        </span>
        <div class="input-content">
          <input
              v-if="!$slots.input"
              class="input"
              :value="modelValue"
              :type="type"
              :disabled="disabled"
              :placeholder="placeholder"
              :max="max"
              @input="handleInput"
              @focus="toggleFocus(true)"
              @blur="toggleFocus(false)"
          />
          <div
              v-else
              class="input"
          >
            <slot name="input" />
          </div>
        </div>
        <span
            v-if="$slots['icon-right']"
            class="icon-container"
            :class="{ clickable: clickableIcon }"
        >
          <slot name="icon-right" />
        </span>
      </div>
    </label>
    <InputBottomMessage
        v-if="!hideError"
        :error-key="error"
        :info-message="infoMessage"
    />
  </div>
</template>

<script>
import LocaleMessages from 'vue-i18n'
import InputBottomMessage from './InputBottomMessage.vue'

export default {
  name: 'InputField',
  components: { InputBottomMessage },
  props: {
    modelValue: {
      type: [Number, String],
      optional: true
    },
    type: {
      type: String,
      optional: true,
      validator: (value) => ['text', 'number', 'password'].includes(value)
    },
    max: {
      type: Number,
      optional: true
    },
    placeholder: {
      type: [String, LocaleMessages],
      optional: true
    },
    label: {
      type: [String, LocaleMessages],
      optional: true
    },
    error: {
      type: String,
      optional: true
    },
    infoMessage: {
      type: String,
      optional: true
    },
    hideError: {
      type: Boolean,
      optional: true
    },
    disabled: {
      type: Boolean,
      optional: true
    },
    clickable: {
      type: Boolean,
      optional: true
    },
    clickableIcon: {
      type: Boolean,
      optional: true
    },
    expand: {
      type: Boolean,
      optional: true
    },
    fitContent: {
      type: Boolean,
      optional: true
    }
  },
  setup () {
    const isFocused = false
    return { isFocused }
  },
  methods: {
    handleClick (event) {
      if (this.$props.clickable) {
        event.preventDefault()
        this.$emit('click')
      }
    },

    handleInput (event) {
      const { value } = event.target
      this.$emit('input', this.$props.type === 'number' ? +value : value)
    },

    toggleFocus (focusValue) {
      this.isFocused = focusValue
      this.$emit(focusValue ? 'focus' : 'blur')
    }
  }
}
</script>

<style lang="scss" scoped>
.input-field {
  --bg-color: #{$color-blue-light};
  --border-color: #{$color-input-border};
  --label-color: #{$color-white};
  --placeholder-color: #{$color-input-placeholder};
  --txt-color: #{$color-white};

  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  width: 100%;

  &.fit-content {
    width: fit-content;
  }

  .input-wrapper {
    display: flex;
    flex-flow: column;
    gap: $spacing-4;
    align-items: start;
    justify-content: flex-start;
    width: 100%;

    .input-label {
      @extend %font-medium-sm;

      color: var(--label-color);
    }
  }

  .input-container {
    @extend %font-medium-sm;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: $input-height;
    padding: $spacing-10 $spacing-16;
    overflow: hidden;
    transition: $transition-base;
    border: 1px solid $color-input-border;
    border-radius: $border-radius-8;
    border-color: var(--border-color);
    background-color: var(--bg-color);
    color: var(--txt-color);
    cursor: text;
    gap: $spacing-8;

    &:hover {
      --border-color: #{$color-primary-20};
    }

    &:focus-within {
      --border-color: #{$color-primary-30};
    }

    &.disabled {
      --border-color: #{$color-blue-light};
      --label-color: #{$color-gray};
      --txt-color: #{$color-gray};

      cursor: not-allowed;

      .input {
        cursor: not-allowed;
      }
    }

    &.error {
      --border-color: #{$color-red};
      --txt-color: #{$color-red};
    }

    &.expand {
      width: 100%;
    }

    &.clickable {
      cursor: pointer;
      user-select: none;

      &.disabled {
        cursor: unset;
      }

      .input-content {
        cursor: pointer;
      }

      .input {
        pointer-events: none;
      }
    }

    .icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;

      &.clickable {
        cursor: pointer;
        pointer-events: auto;
      }
    }

    .input-content {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: start;
      width: 100%;
      cursor: text;

      .input {
        @extend %font-medium-sm;

        width: 100%;
        border: none;
        color: var(--txt-color);
        text-overflow: ellipsis;
        text-align: start;

        &::placeholder {
          color: var(--placeholder-color);
          user-select: none;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          appearance: none;
          margin: 0;
        }
      }
    }
  }
}
</style>
