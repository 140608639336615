<template>
  <h1 class="page-title">
    {{ title }}
  </h1>
</template>

<script>
import LocaleMessages from 'vue-i18n'

export default {
  name: 'PageTitle',
  props: {
    title: [String, LocaleMessages]
  }
}
</script>

<style scoped lang="scss">
.page-title {
  @extend %font-sb-2xl;

  color: $color-white;
}
</style>
