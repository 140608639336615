<template>
  <div class="dashboard">
    <PageTitle :title="$t('common.dashboard')" />

    <PanelBase
      variant="blue"
      expand
    >
      <h5 class="gct-price">
        {{ $t('dashboard.gctPrice') }}
      </h5>

      <SpinnerLoader
        v-if="isLoading"
        is-big
      />
      <div
        v-else
        class="price"
      >
        <h2 class="price-value">
          {{ handleCurrency(price, 6, 6) }}
        </h2>
        <p class="price-operator">
          ({{ PRICE_OPERATOR }})
        </p>
      </div>
    </PanelBase>

    <PanelBase expand>
      <SectionTitle :title="$t('dashboard.earnGct')" :icon="WalletIcon" />
      <p class="text">
        {{ $t('dashboard.lockingGct') }}
      </p>
      <ButtonBase
        :expand="isMobile"
        :fit-content="!isMobile"
        to="/stake"
        medium-size
      >
        {{ $t('dashboard.earnNow') }}
      </ButtonBase>
    </PanelBase>

    <PanelBase expand>
      <SectionTitle :title="$t('dashboard.aboutThePlatform')" :icon="LampIcon" />
      <p class="text">
        {{ $t('dashboard.aboutGct') }}
      </p>
      <ButtonBase
        :expand="isMobile"
        :fit-content="!isMobile"
        :href="ABOUT_GCT_URL"
        variant="outlined"
        medium-size
      >
        {{ $t('common.readMore') }}
      </ButtonBase>
    </PanelBase>
  </div>
</template>

<script>
import callApi from '@/api'
import SpinnerLoader from '@/components/SpinnerLoader.vue'
import { useNotifications, useResize } from '@/composables'
import {
  ABOUT_GCT_URL,
  COINGECKO_PRICE_URL,
  PRICE_OPERATOR
} from '@/constants'
import { handleCurrency } from '@/utils'
import ButtonBase from '@/components/ButtonBase.vue'
import PageTitle from '@/components/PageTitle.vue'
import PanelBase from '@/components/PanelBase.vue'
import SectionTitle from '@/components/SectionTitle.vue'
import LampIcon from '@/assets/icons/lamp-icon.svg'
import WalletIcon from '@/assets/icons/wallet-icon.svg'

export default {
  name: 'dashboard',
  components: { SpinnerLoader, ButtonBase, SectionTitle, PageTitle, PanelBase },
  async created () {
    await this.getSVSPrice()
  },
  data () {
    return {
      isLoading: false,
      price: 0,
      ABOUT_GCT_URL,
      COINGECKO_PRICE_URL,
      PRICE_OPERATOR,
      handleCurrency,
      LampIcon,
      WalletIcon
    }
  },
  methods: {
    async getSVSPrice () {
      this.isLoading = true
      const { success, result } = await callApi.get(COINGECKO_PRICE_URL, {}, true)
      if (success) {
        this.price = result['givingtoservices-svs'].usd
        this.isLoading = false
      } else {
        this.pushNotification({
          titleKey: 'notifications.error.tokenPriceObtain',
          type: 'error'
        })
      }
    }
  },
  setup () {
    const { pushNotification } = useNotifications()
    const { isMobile } = useResize()
    return { isMobile, pushNotification }
  }
}
</script>

<style scoped lang="scss">
.dashboard {
  display: flex;
  flex-direction: column;
  gap: $spacing-24;
  padding: $spacing-32 $spacing-24;
  width: 60%;
  height: fit-content;

  @include mobile {
    padding: $spacing-16 $spacing-20;
    width: 100%;
  }

  .gct-price {
    @extend %font-sb-rg;
  }

  .price {
    display: flex;
    align-items: center;
    gap: $spacing-12;

    &-value {
      @extend %font-sb-2xl;

      font-weight: 800;
    }

    &-operator {
      @extend %font-medium-xs;
    }
  }

  .text {
    @extend %font-medium-rg;
  }
}
</style>
