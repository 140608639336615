import AppLayout from '@/layout/AppLayout.vue'
import AuthLayout from '@/layout/AuthLayout.vue'
import Account from '@/views/Account.vue'
import Dashboard from '@/views/Dashboard.vue'
import ForgetPassword from '@/views/ForgetPassword.vue'
import History from '@/views/History.vue'
import HotWallet from '@/views/HotWallet.vue'
import Login from '@/views/Login.vue'
import NewStake from '@/views/NewStake.vue'
import SetPassword from '@/views/SetPassword.vue'
import Signup from '@/views/Signup.vue'
import Swap from '@/views/Swap.vue'

const routes = [
  {
    path: '',
    component: AuthLayout,
    children: [
      {
        path: '/login',
        alias: '',
        name: 'Login',
        component: Login
      },
      {
        path: '/resetpassword',
        name: 'ResetPassword',
        component: ForgetPassword
      },
      {
        path: '/resetpassword/:uid/:token',
        name: 'SetPassword',
        component: SetPassword
      },
      {
        path: '/signup',
        name: 'Signup',
        component: Signup
      }
    ]
  },
  {
    path: '',
    component: AppLayout,
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard
      },
      {
        path: '/stake',
        name: 'Staking',
        component: NewStake
      },
      {
        path: '/wallet',
        name: 'Wallet',
        component: HotWallet
      },
      {
        path: '/swap',
        name: 'Swap',
        component: Swap
      },
      {
        path: '/account',
        name: 'Account',
        component: Account
      },
      {
        path: '/history',
        name: 'History',
        component: History
      }
    ]
  }
]

export default routes
