<template>
  <div id="app">
    <router-view/>
    <notification-list />
  </div>
</template>

<script>
import { useResize } from '@/composables'
import NotificationList from '@/components/NotificationList.vue'

export default {
  name: 'App',
  components: { NotificationList },
  mounted () {
    const { initResizeListener } = useResize()
    initResizeListener()
  }
}

</script>
