<template>
  <div class="empty-component">
    <img :src="EmptyHistoryBackground" alt="background">
    <p class="text empty-text">
      {{ title }}
    </p>
  </div>
</template>

<script>
import LocaleMessages from 'vue-i18n'
import EmptyHistoryBackground from '@/assets/empty-history.png'

export default {
  name: 'EmptyComponent',
  data () {
    return {
      EmptyHistoryBackground
    }
  },
  props: {
    title: [String, LocaleMessages]
  }
}
</script>

<style scoped lang="scss">
.empty-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-16;
  width: 100%;
  margin-top: $history-empty-margin-top;
  color: $color-white;

  @include mobile {
    margin-top: 0;
  }

  .text {
    @extend %font-medium-rg;
  }

  .empty-text {
    width: $empty-history-width;
    text-align: center;
  }
}
</style>
