<template>
  <div class="app-layout">
    <Sidebar v-if="!isMobile" />
    <div class="app-wrapper">
      <TopBar />
      <div class="wrapper">
        <Component :is="topGradientSvg" class="gradient gradient-top" />
        <Component :is="bottomGradientSvg" class="gradient gradient-bottom" />
        <AppBackground class="app-background" />
        <div class="view-wrapper">
          <router-view />
        </div>
      </div>
      <FooterMenu v-if="isMobile" />
    </div>
  </div>
</template>

<script>
import { useResize } from '@/composables'
import FooterMenu from '@/components/FooterMenu.vue'
import Sidebar from '@/components/SideBar.vue'
import TopBar from '@/components/TopBar.vue'
import AppBackground from '@/assets/app-background.svg'
import GradientDesktopTopSvg from '@/assets/icons/gradient-desktop-top.svg'
import GradientDesktopBottomSvg from '@/assets/icons/gradient-desktop-bottom.svg'
import GradientMobileTopSvg from '@/assets/icons/gradient-mobile-top.svg'
import GradientMobileRightSvg from '@/assets/icons/gradient-mobile-right.svg'

export default {
  name: 'AuthLayout',
  components: {
    AppBackground,
    FooterMenu,
    TopBar,
    Sidebar
  },
  data () {
    return {
      GradientDesktopTopSvg,
      GradientDesktopBottomSvg,
      GradientMobileTopSvg,
      GradientMobileRightSvg
    }
  },
  setup () {
    const { isMobile } = useResize()
    return { isMobile }
  },
  computed: {
    bottomGradientSvg () {
      return this.isMobile ? GradientMobileRightSvg : GradientDesktopBottomSvg
    },
    topGradientSvg () {
      return this.isMobile ? GradientMobileTopSvg : GradientDesktopTopSvg
    }
  }
}
</script>

<style scoped lang="scss">
.app-layout {
  display: flex;
  width: 100%;
  height: 100%;
  background: $color-blue-dark;
  overflow: hidden;

  .app-wrapper {
    width: calc(100% - $sidebar-width);

    @include mobile {
      width: 100%;
    }
  }

  .wrapper {
    position: relative;
    width: 100%;
    height: calc(100% - $topbar-height);

    @include mobile {
      padding-bottom: $footer-height;
    }
  }

  .gradient {
    position: absolute;

    &-top {
      top: 0;
      right: 0;

      @include mobile {
        top: 0;
        left: 0;
        right: unset;
      }
    }

    &-bottom {
      position: absolute;
      bottom: 0;
      left: 0;

      @include mobile {
        top: $app-layout-bottom-gradient-top;
        bottom: unset;
        right: 0;
        left: unset;
      }
    }
  }

  .app-background {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    width: 96%;

    @include mobile {
      width: 100%;
      transform: translate(-50%, 20%);
    }
  }

  .view-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: $z-index-wrapper;
    overflow-y: auto;
  }
}
</style>
