<template>
  <div class="bullet-point">
    <div class="point-wrapper">
      <IconComponent
          class="point-wrapper-bg"
          :icon="IconWrapperSvg"
      />
      <IconComponent
        :icon="ArrowRightIcon"
        size="sm"
      />
    </div>
    <h4 class="point-text">
      {{ text }}
    </h4>
  </div>
</template>

<script>
import LocaleMessages from 'vue-i18n'
import IconComponent from '@/components/IconComponent.vue'
import ArrowRightIcon from '@/assets/icons/arrow-right.svg'
import IconWrapperSvg from '@/assets/icons/icon-wrapper.svg'

export default {
  name: 'BulletPoint',
  components: {
    IconComponent
  },
  data () {
    return {
      ArrowRightIcon,
      IconWrapperSvg
    }
  },
  props: {
    text: {
      type: [String, LocaleMessages]
    }
  }
}
</script>

<style scoped lang="scss">
.bullet-point {
  display: flex;
  gap: $spacing-12;

  .point-wrapper {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    position: relative;
    width: $bullet-point-size;
    height: $bullet-point-size;
    border-radius: 50%;

    &-bg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .point-text {
    @extend %font-medium-rg
  }
}
</style>
