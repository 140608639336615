export const ERROR_MESSAGES = {
  accountNotAllowed: 'notifications.error.accountNotAllowed',
  amountEmpty: 'notifications.error.amountEmpty',
  amountExceeds: 'notifications.error.amountExceeds',
  amountFee: 'notifications.error.amountFee',
  amountNegative: 'notifications.error.amountNegative',
  asciiOnly: 'notifications.error.asciiOnly',
  default: 'notifications.error.default',
  emailRegistered: 'notifications.error.emailRegistered',
  emailSentFailed: 'notifications.error.emailSentFailed',
  exchangeRate: 'notifications.error.exchangeRate',
  insufficientLiquidity: 'notifications.error.insufficientLiquidity',
  invalidAmount: 'notifications.error.invalidAmount',
  invalid2faCode: 'notifications.error.invalid2faCode',
  invalidChangeToken: 'notifications.error.invalidChangeToken',
  invalidCredentials: 'notifications.error.invalidCredentials',
  invalidToken: 'notifications.error.invalidToken',
  loginFscs: 'notifications.error.loginFscs',
  minimumXrpWithdraw: 'notifications.error.minimumXrpWithdraw',
  newStakingInfoObtain: 'notifications.error.newStakingInfoObtain',
  noTrustline: 'notifications.error.noTrustline',
  passwordArentSame: 'notifications.error.passwordArentSame',
  slippageExceed: 'notifications.error.slippageExceed',
  stakingClosed: 'notifications.error.stakingClosed',
  swapAmountExceeds: 'notifications.error.swapAmountExceeds',
  swapFromSvsForbidden: 'notifications.error.swapFromSvsForbidden',
  swapInfoObtain: 'notifications.error.swapInfoObtain',
  tokenPermissionNotExists: 'notifications.error.tokenPermissionNotExists',
  tokenPriceObtain: 'notifications.error.tokenPriceObtain',
  tokenRequired: 'notifications.error.tokenRequired',
  transactionForbidden: 'notifications.error.transactionForbidden',
  unknownToken: 'notifications.error.unknownToken',
  userAlreadyExistsAndConfigured: 'notifications.error.userAlreadyExistsAndConfigured',
  userInfoObtain: 'notifications.error.userInfoObtain',
  usernameTaken: 'notifications.error.usernameTaken',
  userNotExists: 'notifications.error.userNotExists',
  userWithoutKyc: 'notifications.error.userWithoutKyc',
  walletInfoObtain: 'notifications.error.walletInfoObtain',
  withdrawSuspended: 'notifications.error.withdrawSuspended',
  wrongDepositAddress: 'notifications.error.wrongDepositAddress',
  wrongPair: 'notifications.error.wrongPair'
}
