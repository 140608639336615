<template>
  <div class="notification-list">
    <TransitionGroup name="list-slide">
      <NotificationItem
        v-for="notification in notificationList"
        :id="notification.id"
        :key="notification.id"
        :title-key="notification.titleKey"
        :title-properties="notification.titleProperties"
        :type="notification.type"
      />
    </TransitionGroup>
  </div>
</template>

<script>
import { useNotifications } from '@/composables'
import NotificationItem from '@/components/NotificationItem.vue'

export default {
  name: 'NotificationList',
  components: { NotificationItem },
  setup () {
    const { notificationList } = useNotifications()
    return { notificationList }
  }
}
</script>

<style scoped lang="scss">
.notification-list {
  display: flex;
  gap: $spacing-4;
  position: absolute;
  z-index: $z-index-notification;
  right: 40px;
  bottom: 40px;
  flex-flow: column;
  align-items: flex-end;

  @include mobile {
    left: 10px;
    right: 10px;
    bottom: 10px;
  }
}
</style>
