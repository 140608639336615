<template>
  <div class="history">
    <PageTitle :title="$t('common.history')" />

    <SpinnerLoader
      v-if="isLoading"
      is-big
    />

    <EmptyComponent
      v-else-if="!originalData.length"
      :title="$t('history.empty')"
    />

    <div
      v-else
      class="content-wrapper"
    >
      <InputField
          v-model="searchValue"
          @input="handleSearch"
          class="search-input"
          expand
          hide-error
          :placeholder="$t('common.search')"
      >
        <template #icon-left>
          <IconComponent
              :icon="SearchIcon"
              size="sm"
          />
        </template>
      </InputField>

      <EmptyComponent
        v-if="!data.length"
        :title="$t('common.noResults')"
      />

      <TableComponent
        v-else
        :data="data"
        :header-config="headerConfig"
        :horizontal-scroll="isMobile"
        :sort="sort"
        @sort="(sortValue) => handleSort(sortValue)"
      />
    </div>

  </div>
</template>

<script>
import SpinnerLoader from '@/components/SpinnerLoader.vue'
import { orderBy } from 'lodash'
import callApi from '@/api'
import { useResize } from '@/composables'
import EmptyComponent from '@/components/EmptyComponent.vue'
import IconComponent from '@/components/IconComponent.vue'
import InputField from '@/components/InputField.vue'
import PageTitle from '@/components/PageTitle.vue'
import TableComponent from '@/components/table/TableComponent.vue'
import SearchIcon from '@/assets/icons/search-icon.svg'

export default {
  name: 'History',
  components: { SpinnerLoader, TableComponent, EmptyComponent, IconComponent, InputField, PageTitle },
  data () {
  	return {
      data: [],
      isLoading: false,
      originalData: [],
      headerConfig: {
        token: {
          labelKey: 'history.table.token',
          sortable: true
        },
        status: {
          labelKey: 'history.table.status',
          sortable: true
        },
        amount: {
          labelKey: 'history.table.amount',
          sortable: true
        },
        created_at: {
          labelKey: 'history.table.created',
          sortable: true
        }
      },
      searchValue: '',
      sort: undefined,
      SearchIcon
  	}
  },
  async created () {
    await this.fetchData()
  },
  methods: {
    async fetchData () {
      this.isLoading = true
      const { success, result } = await callApi.get('/api/get-history')
      if (success) {
        this.originalData = result.histories
        this.data = result.histories
      }
      this.isLoading = false
    },
    handleSearch (value) {
      if (!value) {
        this.data = this.originalData
        return
      }
      this.data = this.originalData.filter(obj =>
        Object.values(obj).some(value =>
          value.toString().toLowerCase().includes(this.searchValue.toLowerCase())
        )
      )
    },
    handleSort (value) {
      this.sort = value
      this.data = orderBy(this.data, this.sort.field, this.sort.order.toLowerCase())
    }
  },
  setup () {
    const { isMobile } = useResize()
    return { isMobile }
  }
}
</script>

<style scoped lang="scss">
.history {
  display: flex;
  flex-direction: column;
  gap: $spacing-24;
  padding: $spacing-32 $spacing-24;
  width: 60%;
  color: $color-white;

  @include mobile {
    padding: unset;
    width: 100%;
  }

  .text {
    @extend %font-medium-rg;
  }

  .search-input {
    width: 50%;

    @include mobile {
      width: 100%;
    }
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    gap: $spacing-16;
  }
}
</style>
