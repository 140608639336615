<template>
  <Component
      :is="icon"
      class="icon-component"
      :class="[size, {
      'unset-width': unsetWidth,
      'unset-height': unsetHeight,
    }]"
  />
</template>

<script>
export default {
  name: 'IconComponent',
  props: {
    icon: [String, Object],
    size: {
      type: String,
      default: 'rg',
      optional: true,
      validator: (value) => ['xs', 'sm', 'rg', 'md', 'lg', 'xl'].includes(value)
    },
    unsetWidth: {
      ype: Boolean,
      optional: true
    },
    unsetHeight: {
      type: Boolean,
      optional: true
    }
  }
}
</script>

<style scoped lang="scss">
.icon-component {
  --icon-size: 24px;
  --icon-size-width: var(--icon-size);
  --icon-size-height: var(--icon-size);

  display: flex;
  flex: none;
  min-width: var(--icon-size-width);
  max-width: var(--icon-size-width);
  min-height: var(--icon-size-height);
  max-height: var(--icon-size-height);

  &.xl {
    --icon-size: 48px;
  }

  &.lg {
    --icon-size: 32px;
  }

  &.md {
    --icon-size: 20px;
  }

  &.sm {
    --icon-size: 16px;
  }

  &.xs {
    --icon-size: 8px;
  }

  &.unset-width {
    --icon-size-width: unset;
  }

  &.unset-height {
    --icon-size-height: unset;
  }
}
</style>
