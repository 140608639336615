<template>
  <div class="text-ellipsis">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'TextEllipsis'
}
</script>

<style scoped lang="scss">
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
