import { useNotifications } from '@/composables'
import { ERROR_MESSAGES } from '@/constants'

export function handleErrorNotification (error) {
  const { pushNotification } = useNotifications()

  pushNotification({
    titleKey: ERROR_MESSAGES[error] || ERROR_MESSAGES.default,
    type: 'error'
  })
}
