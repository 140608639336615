import { onMounted, ref } from 'vue'
import { DESKTOP_WIDTH, MOBILE_WIDTH } from '@/constants'

const isDesktop = ref(false)
const isTablet = ref(false)
const isMobile = ref(false)

export function useResize () {
  function handleResize () {
    isDesktop.value = window.innerWidth >= DESKTOP_WIDTH
    isMobile.value = window.innerWidth <= MOBILE_WIDTH
    isTablet.value = window.innerWidth < DESKTOP_WIDTH && window.innerWidth > MOBILE_WIDTH
  }

  function initResizeListener () {
    window.addEventListener('resize', handleResize)
  }

  onMounted(handleResize)

  return {
    isDesktop,
    isMobile,
    isTablet,
    initResizeListener
  }
}
