<template>
  <div class="auth-layout">
    <Component :is="topGradientSvg" class="gradient gradient-top" />
    <Component :is="bottomGradientSvg" class="gradient gradient-bottom" />
    <div class="view-wrapper">
      <router-view />
    </div>
  </div>
</template>

<script>
import { useResize } from '@/composables'
import GradientDesktopTopSvg from '@/assets/icons/gradient-desktop-top.svg'
import GradientDesktopBottomSvg from '@/assets/icons/gradient-desktop-bottom.svg'
import GradientMobileTopSvg from '@/assets/icons/gradient-mobile-top.svg'
import GradientMobileBottomSvg from '@/assets/icons/gradient-mobile-bottom.svg'

export default {
  name: 'AuthLayout',
  data () {
    return {
      GradientDesktopTopSvg,
      GradientDesktopBottomSvg,
      GradientMobileTopSvg,
      GradientMobileBottomSvg
    }
  },
  setup () {
    const { isMobile } = useResize()
    return { isMobile }
  },
  computed: {
    bottomGradientSvg () {
      return this.isMobile ? GradientMobileBottomSvg : GradientDesktopBottomSvg
    },
    topGradientSvg () {
      return this.isMobile ? GradientMobileTopSvg : GradientDesktopTopSvg
    }
  }
}
</script>

<style scoped lang="scss">
.auth-layout {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: $color-blue-dark;
  position: relative;
  overflow: hidden;

  .gradient {
    position: absolute;

    &-top {
      top: 0;
      right: 0;

      @include mobile {
        left: 0;
        right: unset;
      }
    }

    &-bottom {
      position: absolute;
      bottom: 0;
      left: 0;

      @include mobile {
        right: 0;
        left: unset;
      }
    }
  }

  .view-wrapper {
    position: relative;
    z-index: $z-index-wrapper;
    width: 100%;
    height: 100%;
  }
}
</style>
