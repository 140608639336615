import { onBeforeUnmount, onMounted } from 'vue'

export function useClickOutside ({
  active, callback, component, excludeComponent
}) {
  if (!callback) {
    throw new Error('A callback has to be provided.')
  }

  if (!component) {
    throw new Error('A target component has to be provided.')
  }

  const listener = (event) => {
    if (
      active.value &&
      event.target !== component.value &&
      !event.composedPath().includes(component.value) &&
      event.target !== excludeComponent.value &&
      !event.composedPath().includes(excludeComponent.value)
    ) {
      callback()
    }
  }

  onMounted(() => {
    window.addEventListener('mousedown', listener)
  })

  onBeforeUnmount(() => {
    window.removeEventListener('mousedown', listener)
  })
}
