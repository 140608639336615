<template>
  <div
      class="select-base"
      :class="{ 'fit-content': fitContent }"
  >
    <div ref="excludeComponentRef">
      <InputField
          :model-value="visibleValue"
          :label="label"
          :error="error"
          :info-message="infoMessage"
          :placeholder="placeholder"
          :disabled="disabled"
          :hide-error="hideError"
          clickable
          @click="toggleOptions(!optionsVisible)"
      >
        <template
            v-if="$slots.input"
            #input
        >
          <slot
            name="input"
            :value="selectedOption"
          />
        </template>
        <template #icon-left>
          <slot name="icon-left" />
        </template>
        <template #icon-right>
          <IconComponent
              :icon="ChevronIcon"
              class="chevron-icon"
              size="rg"
              :class="{ flipped: optionsVisible }"
          />
        </template>
      </InputField>
    </div>

    <div
        ref="targetComponentRef"
        class="option-list"
        :class="{
        visible: optionsVisible,
        'fully-expanded': fullyExpanded,
        'position-top': positionTop,
        'without-error': hideError,
      }"
    >
      <div
        v-for="(option, index) in options"
        :key="option.value"
        ref="optionsRef"
        class="single-option"
        :class="{selected: isOptionSelected && isOptionSelected(option.value)}"
        tabindex="0"
        @click.prevent="handleInput(option.value)"
        @keydown.space.enter="handleInput(option.value)"
        @keydown.up.prevent="handleFocusOption(index - 1)"
        @keydown.down.prevent="handleFocusOption(index + 1)"
      >
        <slot
          v-if="$slots.option"
          name="option"
          :option="option"
          :selected="isOptionSelected(option.value)"
        />
        <template v-else>
          {{ option.label }}
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { useClickOutside } from '@/composables'
import IconComponent from '@/components/IconComponent.vue'
import InputField from '@/components/InputField.vue'
import ChevronIcon from '@/assets/icons/chevron-down.svg'
import LocaleMessages from 'vue-i18n'

export default {
  name: 'SelectInput',
  components: {
    IconComponent,
    InputField
  },
  computed: {
    selectedOption () {
      return this.options?.find((option) => option.value === this.modelValue)
    },
    visibleValue () {
      return this.selectedOption && this.selectedOption.label ? this.selectedOption.label : this.modelValue
    }
  },
  data () {
    return {
      fullyExpanded: false,
      optionsVisible: false,
      ChevronIcon
    }
  },
  methods: {
    isOptionSelected (option) {
      return option === this.modelValue
    },
    toggleOptions (isOpen) {
      if (this.disabled) {
        return
      }
      this.optionsVisible = isOpen
    },
    handleFocusOption (focusIndex) {
      const { length } = this.$refs.optionsRef
      if (!focusIndex || focusIndex >= length) {
        this.$refs.optionsRef[0].focus()
        return
      }
      if (focusIndex < 0) {
        this.$refs.optionsRef[length - 1].focus()
        return
      }
      this.$refs.optionsRef[focusIndex].focus()
    },
    handleInput (value) {
      this.$emit('input', value)
      this.toggleOptions(false)
    }
  },
  props: {
    options: {
      type: Array,
      optional: true
    },
    modelValue: {
      type: [Number, String],
      optional: true
    },
    type: {
      type: String,
      optional: true,
      validator: (value) => ['text', 'number', 'password'].includes(value)
    },
    max: {
      type: Number,
      optional: true
    },
    placeholder: {
      type: [String, LocaleMessages],
      optional: true
    },
    label: {
      type: [String, LocaleMessages],
      optional: true
    },
    error: {
      type: String,
      optional: true
    },
    infoMessage: {
      type: String,
      optional: true
    },
    hideError: {
      type: Boolean,
      optional: true
    },
    disabled: {
      type: String,
      optional: true
    },
    clickable: {
      type: Boolean,
      optional: true
    },
    clickableIcon: {
      type: Boolean,
      optional: true
    },
    expand: {
      type: Boolean,
      optional: true
    },
    fitContent: {
      type: Boolean,
      optional: true
    },
    positionTop: {
      type: Boolean,
      optional: true
    }
  },
  mounted () {
    useClickOutside({
      active: this.optionsVisible,
      callback: () => this.toggleOptions(false),
      component: this.$refs.targetComponentRef,
      excludeComponent: this.$refs.excludeComponentRef
    })
  },
  watch: {
    optionsVisible (value) {
      if (value) {
        setTimeout(() => {
          this.fullyExpanded = true
        }, 600)
      } else {
        this.fullyExpanded = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.select-base {
  position: relative;
  width: 100%;

  &.fit-content {
    width: fit-content;
  }

  .chevron-icon {
    transition: $transition-ease;

    &.flipped {
      transform: rotate(180deg);
    }
  }

  .option-list {
    display: flex;
    position: absolute;
    z-index: $z-index-select-options;
    top: 100%;
    left: 0;
    flex-direction: column;
    gap: $spacing-4;
    width: 100%;
    max-height: 0;
    padding: $spacing-4 0;
    overflow: hidden;
    transition: $transition-base;
    border: 1px solid $color-input-border;
    border-radius: $border-radius-8;
    opacity: 0;
    background: $color-primary-100;
    color: $color-white;
    user-select: none;

    &.visible {
      max-height: 200px;
      opacity: 1;
    }

    &.fully-expanded {
      overflow-y: auto;
    }

    &.position-top {
      top: unset;
      bottom: 100%;
    }

    .single-option {
      @extend %font-medium-rg;

      padding: $spacing-8 $spacing-16;
      outline: none;
      color: $color-white;
      cursor: pointer;
      user-select: none;

      &:hover,
      &:active,
      &:focus-visible {
        background-color: $color-primary-50;
      }

      &.selected {
        background-color: $color-primary-70;
      }
    }
  }
}
</style>
