<template>
  <div class="checkbox-base">
    <label
        class="checkbox-wrapper"
        @keydown.enter="handleChange"
    >
      <div
          class="checkbox-input"
          :class="{ checked: modelValue, error }"
      >
        <IconComponent
            :icon="TickIcon"
            size="sm"
            class="icon"
        />
        <input
            :checked="modelValue"
            type="checkbox"
            class="input"
            @input="handleChange"
        />
      </div>
      <div class="checkbox-label">
        {{ label }}
      </div>
    </label>
    <InputBottomMessage
        v-if="!hideError"
        :error-key="error"
        :info-message="infoMessage"
    />
  </div>
</template>

<script>
import LocaleMessages from 'vue-i18n'
import IconComponent from '@/components/IconComponent.vue'
import InputBottomMessage from '@/components/InputBottomMessage.vue'
import TickIcon from '@/assets/icons/tick-icon.svg'

export default {
  name: 'CheckboxBase',
  components: { IconComponent, InputBottomMessage },
  data () {
    return { TickIcon }
  },
  props: {
    modelValue: {
      type: Boolean,
      optional: true
    },
    label: {
      type: [String, LocaleMessages],
      optional: true
    },
    hideError: {
      type: Boolean,
      optional: true
    },
    error: {
      type: String,
      optional: true
    },
    infoMessage: {
      type: String,
      optional: true
    }
  },
  methods: {
    handleChange () {
      this.$emit('input', !this.modelValue)
    }
  }
}
</script>

<style scoped lang="scss">
.checkbox-base {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  .checkbox-wrapper {
    @extend %font-medium-sm;

    display: flex;
    align-items: center;
    width: 100%;
    gap: $spacing-8;
    color: $color-white;
    cursor: pointer;
    user-select: none;

    &.disabled {
      color: $color-gray;
      cursor: not-allowed;
    }

    .checkbox-input {
      display: flex;
      position: relative;
      flex: none;
      align-items: center;
      justify-content: center;
      width: $checkbox-size;
      height: $checkbox-size;
      margin: $spacing-4;
      overflow: hidden;
      transition: $transition-base;
      border: 1px solid $color-primary-10;
      border-radius: $border-radius-4;
      background: transparent;
      cursor: pointer;

      &:hover {
        background: $color-primary-80;
      }

      .icon {
        transition: $transition-base;
        opacity: 0;
      }

      &.checked {
        background: $color-primary-20;

        &:hover {
          background: $color-primary-10;
        }

        .icon {
          opacity: 1;
        }
      }

      &.error {
        border-color: $color-red;
      }

      .input {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        all: unset;
        inset: 0;
      }
    }

    .checkbox-label {
      display: inline;
      text-align: start;
    }
  }
}
</style>
