<template>
  <Component
      :is="component"
      :type="type"
      :disabled="disabled || loading"
      :to="to"
      :href="href"
      :target="href ? '_blank' : null"
      :rel="href ? 'noopener noreferrer' : null"
      class="button"
      :class="[
        variant,
        {
          disabled,
          expand,
          loading,
          'fit-content': fitContent,
          'medium-size': mediumSize
        },
      ]"
      @click="(e) => $emit('click', e)"
  >
    <SpinnerLoader v-if="loading" />
    <slot v-else />
  </Component>
</template>

<script>
import SpinnerLoader from '@/components/SpinnerLoader.vue'

export default {
  name: 'ButtonBase',
  components: { SpinnerLoader },
  props: {
    to: {
      type: [Object, String],
      optional: true
    },
    href: {
      type: String,
      optional: true
    },
    variant: {
      type: String,
      default: 'primary',
      optional: true,
      validator: (value) => ['primary', 'secondary', 'outlined', 'menu', 'footer', 'icon'].includes(value)
    },
    disabled: {
      type: Boolean,
      optional: true
    },
    loading: {
      type: Boolean,
      optional: true
    },
    expand: {
      type: Boolean,
      optional: true
    },
    fitContent: {
      type: Boolean,
      optional: true
    },
    mediumSize: {
      type: Boolean,
      optional: true
    },
    type: {
      type: String,
      default: 'button',
      optional: true,
      validator: (value) => ['button', 'submit'].includes(value)
    }
  },
  computed: {
    component () {
      if (this.to) {
        return 'RouterLink'
      }
      if (this.href) {
        return 'a'
      }
      return 'button'
    }
  }
}
</script>

<style scoped lang="scss">
.button {
  --bg-color: #{$color-button-primary};
  --bg-active-color: #{$color-primary-30};
  --bg-hover-color: #{$color-primary-20};
  --border-color: #{$color-primary-20};
  --border-color-focus: #{$color-primary-40};
  --border-color-hover: #{$color-primary-30};
  --text-color: #{$color-white};

  @extend %font-sb-sm;

  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: $spacing-4 $spacing-16;
  border: 1px solid var(--border-color);
  border-radius: $border-radius-8;
  background: var(--bg-color);
  height: fit-content;
  color: var(--text-color);
  gap: $spacing-4;
  cursor: pointer;

  &:hover {
    background: var(--bg-hover-color);
    border-color: var(--border-color-hover);
  }

  &:active {
    background: var(--bg-active-color);
  }

  &:focus {
    border-color: var(--border-color-focus);
  }

  &.disabled,
  &.loading {
    opacity: 0.6;
    border-color: transparent;
    cursor: not-allowed;

    &:hover {
      border-color: transparent;
      background: var(--bg-color);
    }
  }

  &.expand {
    width: 100%;
  }

  &.fit-content {
    width: fit-content;
  }

  &.medium-size {
    padding: $spacing-8 $spacing-24;
    gap: $spacing-8;
  }

  // VARIANTS
  &.secondary {
    --bg-color: #{$color-primary-05};
    --bg-active-color: #{$color-primary-20};
    --bg-hover-color: #{$color-primary-10};
    --border-color: transparent;
    --border-color-focus: #{$color-primary-20};
    --border-color-hover: transparent;
    --text-color: #{$color-blue-dark};
  }

  &.outlined {
    --bg-color: transparent;
    --bg-active-color: transparent;
    --bg-hover-color: transparent;
    --border-color: #{$color-white};
    --border-color-focus: #{$color-white};
    --border-color-hover: #{$color-primary-05};
    --text-color: #{$color-white};

    &:active {
      border-color: #{$color-primary-20};
      color: #{$color-primary-20};
    }

    &.disabled {
      border-color: #{$color-primary-10};
      color: #{$color-primary-10};
    }

    &:hover {
      --text-color: var(--border-color-hover);
    }
  }

  &.menu {
    @extend %font-medium-lg;

    --bg-color: transparent;
    --bg-hover-color: #{$color-button-menu-hover};
    --bg-active-color: var(--bg-color);
    --border-color: transparent;
    --border-color-focus: var(--border-color);
    --border-color-hover: var(--border-color);

    justify-content: start;
    gap: $spacing-12;
    padding: $spacing-12 $spacing-16;
    width: 100%;
  }

  &.footer {
    @extend %font-medium-xs;

    --bg-color: transparent;
    --bg-hover-color: var(--bg-color);
    --bg-active-color: var(--bg-color);
    --border-color: transparent;
    --border-color-focus: var(--border-color);
    --border-color-hover: var(--border-color);

    flex-direction: column;
    padding: $spacing-4;
  }

  &.router-link-active {
    --bg-color: #{$color-button-menu-active};
    --border-color: #{$color-input-border};
    --border-color-hover: transparent;

    @include mobile {
      --bg-color: transparent;
      --border-color: transparent;
    }
  }

  &.icon {
    --bg-color: transparent;
    --bg-active-color: var(--bg-color);
    --bg-hover-color: #{$color-button-menu-hover};
    --border-color: transparent;
    --border-color-focus: var(--border-color);
    --border-color-hover: var(--border-color);

    padding: $spacing-8;
    border-radius: $border-radius-8;
  }
}
</style>
