<template>
  <div class="sidebar">
    <GctLogo class="logo" />
    <div class="wrapper">
      <div class="button-wrapper">
        <ButtonBase
          v-for="element in sidebarConfig"
          :key="element.name"
          :to="element.to"
          variant="menu"
        >
          <IconComponent
            :icon="element.icon"
            size="md"
          />
          {{ $t(element.name) }}
        </ButtonBase>
      </div>
      <ButtonBase
        variant="menu"
        @click="logout"
      >
        <IconComponent
          :icon="LogoutIcon"
          size="md"
        />
          {{ $t('common.logout') }}
      </ButtonBase>
    </div>
  </div>
</template>

<script>
import { STORAGE_KEY_USER_INFO } from '@/constants'
import { removeFromLocalStorage } from '@/utils'
import ButtonBase from '@/components/ButtonBase.vue'
import IconComponent from '@/components/IconComponent.vue'
import GctLogo from '@/assets/gct-logo.svg'
import CategoryIcon from '@/assets/icons/category-icon.svg'
import EarnIcon from '@/assets/icons/earn-icon.svg'
import HistoryIcon from '@/assets/icons/history-icon.svg'
import LogoutIcon from '@/assets/icons/logout-icon.svg'
import SwapIcon from '@/assets/icons/swap-icon.svg'
import UserIcon from '@/assets/icons/user-icon.svg'
import WalletIcon from '@/assets/icons/wallet-icon.svg'

export default {
  name: 'Sidebar',
  components: {
    GctLogo,
    IconComponent,
    ButtonBase
  },
  data () {
    return {
      CategoryIcon,
      HistoryIcon,
      LogoutIcon,
      SwapIcon,
      EarnIcon,
      UserIcon,
      WalletIcon
    }
  },
  methods: {
    logout () {
      removeFromLocalStorage(STORAGE_KEY_USER_INFO)
      window.location.href = '/logout'
    }
  },
  setup () {
    const sidebarConfig = [
      {
        name: 'common.dashboard',
        icon: CategoryIcon,
        to: '/dashboard'
      },
      {
        name: 'common.wallet',
        icon: WalletIcon,
        to: '/wallet'
      },
      {
        name: 'common.earn',
        icon: EarnIcon,
        to: '/stake'
      },
      {
        name: 'common.swap',
        icon: SwapIcon,
        to: '/swap'
      },
      {
        name: 'common.history',
        icon: HistoryIcon,
        to: '/history'
      },
      {
        name: 'common.account',
        icon: UserIcon,
        to: '/account'
      }
    ]
    return { sidebarConfig }
  }
}
</script>

<style scoped lang="scss">

.sidebar {
  @include generate-shadow($sidebar-shadow);

  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  gap: $spacing-32;
  width: $sidebar-width;
  height: 100%;
  padding: $spacing-20 $spacing-16;
  background-color: $color-blue;
  color: $color-white;
  z-index: $z-index-wrapper;

  .logo {
    height: $logo-height;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  .button-wrapper {
    display: flex;
    flex-direction: column;
    gap: $spacing-12;
  }
}
</style>
