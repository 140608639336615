<template>
  <div
      class="spinner-loader"
      :class="{ big: isBig }"
  />
</template>

<script>
export default {
  name: 'SpinnerLoader',
  props: {
    isBig: {
      type: Boolean,
      required: false
    }
  }
}
</script>

<style scoped lang="scss">
.spinner-loader {
  --size: #{$loader-size};

  width: var(--size);
  height: var(--size);
  animation: rotation 1s linear infinite;
  border: 2px solid currentcolor;
  border-radius: 50%;
  border-bottom-color: transparent;

  &.big {
    --size: #{$loader-size-big};
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
