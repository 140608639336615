import axios from 'axios'
import { handleErrorNotification } from '@/utils/error'

export const apiInstance = axios.create({
  headers: {
    'Content-Type': 'application/json'
  },
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFTOKEN'
})

async function callApiHandler (options) {
  const config = {
    ...options,
    ...('data' in options && options.data),
    params: { ...options.params },
    responseType: 'json'
  }

  try {
    const result = await apiInstance(config)
    const { data } = result

    return {
      success: true,
      result: data
    }
  } catch (apiError) {
    const { data, status } = apiError?.response || {}

    const omitErrorNotification = 'omitErrorNotification' in options && options.omitErrorNotification
    if (!omitErrorNotification) {
      handleErrorNotification(data.message)
    }

    return {
      success: false,
      result: data,
      status
    }
  }
}

const callApi = {
  post: (
    url,
    data,
    params,
    omitErrorNotification
  ) => callApiHandler({
    method: 'post',
    url,
    params,
    data,
    omitErrorNotification
  }),
  get: (url, params, omitErrorNotification) => callApiHandler({
    method: 'get',
    url,
    params,
    omitErrorNotification
  })
}

export default callApi
